import { useState } from 'react';
import { useRouter } from 'next/router';
import clsx from 'clsx';
import { useSearchParam } from 'react-use';
import { isDevelopment } from '@/utils/isDevelopment';
import { Button } from '@/components/Button';
import { Pagination } from '@/components/Pagination';
import { Warning } from '@/components/Warning';
import { useAppReviews, useEntry } from '../App.hooks';
import { ReviewStars } from '../AppCard/ReviewStars';
type Props = {
  className?: string;
};
const PAGE_LIMIT = 4;
export const AppReviews = ({
  className
}: Props) => {
  const {
    locale
  } = useRouter();
  const entry = useEntry();
  const appId = 'error' in entry ? null : entry.data?.appRegistryId;
  const pageSearchParam = useSearchParam('page');
  const [page, setPage] = useState(typeof pageSearchParam === 'string' ? Number(pageSearchParam) : 1);
  const reviews = useAppReviews(Number(appId));
  if ('error' in entry) {
    if (isDevelopment()) return <Warning>{entry.error}</Warning>;
    return null;
  }
  const hasReviews = reviews?.length > 0;
  const {
    rating,
    reviewCount
  } = entry.data ?? {};
  return <section className={clsx(className, 'grid py-24')} data-sentry-component="AppReviews" data-sentry-source-file="AppReviews.tsx">
			<div className="grid grid-flow-row gap-4">
				<h1 className="text-bc-black text-2xl font-medium m-0">Customer Reviews</h1>

				<div className="grid grid-flow-col w-max">
					<ReviewStars rating={Number(rating?.toFixed(2))} data-sentry-element="ReviewStars" data-sentry-source-file="AppReviews.tsx" />
					<p className="text-gray-500 text-sm ml-1">{`${rating?.toFixed(2)} out of 5 stars with ${reviewCount} reviews`}</p>
				</div>

				<div className="grid grid-flow-col gap-4 w-fit">
					<Button link={{
          href: `https://login.bigcommerce.com/deep-links/marketplace/apps/${appId}/reviews/edit`
        }} color="primary" variant="filled" dataTestId="write-review-btn" data-sentry-element="Button" data-sentry-source-file="AppReviews.tsx">
						Write a review
					</Button>

					{entry?.data?.supportUrl && <a href={entry.data.supportUrl} className="text-bc-black bg-white text-sm font-medium px-4 py-2 border border-bc-black rounded-md" data-testid="get-support-btn">
							Get support
						</a>}
				</div>
			</div>

			{hasReviews && <>
					<div className="grid pt-4 my-10 gap-10">
						{reviews?.slice((page - 1) * PAGE_LIMIT, page * PAGE_LIMIT)?.map(review => <div key={review.id} className="">
								<ReviewStars rating={review.rating} />

								<h2 className="text-bc-black text-xl font-medium py-4">{review.subject}</h2>

								<span className="text-gray-500 text-xs">
									{new Intl.DateTimeFormat(locale, {
              dateStyle: 'medium'
            }).format(new Date(review.updated_at * 1000))}
								</span>

								<p className="text-gray-500 pt-4">{review.content}</p>
							</div>)}
					</div>

					<Pagination limit={PAGE_LIMIT} total={reviews.length} current={page} handlePageChange={setPage} className="mt-10 ml-0 mr-0 pl-0 pr-0" />
				</>}
		</section>;
};
import clsx from 'clsx';
type Props = {
  page: number | string;
  current: number;
  type?: string;
  handlePageChange?: (page: number) => void;
  scrollToTop?: boolean;
};
const getNewPage = ({
  type,
  current,
  page
}: Omit<Props, 'handlePageChange' | 'scrollToTop'>) => {
  if (type === 'next') return current + 1;
  if (type === 'prev') return current - 1;
  return (page as number);
};
export function PaginationLink({
  current,
  page,
  type,
  handlePageChange,
  scrollToTop = false
}: Props) {
  const onPageChange = () => {
    const newPage = getNewPage({
      current,
      page,
      type
    });
    global?.history?.replaceState(null, '', `?page=${newPage}`);
    handlePageChange?.(newPage);
    if (scrollToTop) {
      scrollTo({
        top: 0
      });
    }
  };
  return <button className={clsx(current === page && 'text-gray-700 cursor-default', 'text-bc-blue font-normal text-[17px]')} key={page} onClick={onPageChange} data-sentry-component="PaginationLink" data-sentry-source-file="PaginationLink.tsx">
			{page}
		</button>;
}